import {TRANSLATION_KEYS} from './translation-keys';

export const BASE_URL = 'https://cl.api.cargonet.com.uy'; //PROD
export const APP_BASE_URL = BASE_URL + '/app/v1/';
export const ANALYTICS_BASE_URL = BASE_URL + '/analytics/v1/'; //ANALYTICS
export const OPERATIONS_BASE_URL = BASE_URL + '/operations/v1/';
export const WS_BASE_URL = BASE_URL + '/ws/v1/ws/';
export const VERSION = '1.1.0';
export const ANIMATION_DURATION = 250;

export const PERMISSION = {
    ALTA_COTIZACION: 'ALTA_COTIZACION',
    ALTA_ORGANIZACION: 'ALTA_ORGANIZACION',
    ALTA_USUARIO: 'ALTA_USUARIO',
    BAJA_USUARIO: 'BAJA_USUARIO',
    CONSULTAR_AGENTE: 'CONSULTAR_AGENTE',
    CONSULTAR_CLIENTE: 'CONSULTAR_CLIENTE',
    CONSULTAR_COMMODITY: 'CONSULTAR_COMMODITY',
    CONSULTAR_COTIZACION: 'CONSULTAR_COTIZACION',
    CONSULTAR_COUNTRY: 'CONSULTAR_COUNTRY',
    CONSULTAR_ORGANIZACION: 'CONSULTAR_ORGANIZACION',
    CONSULTAR_UNLOCO: 'CONSULTAR_UNLOCO',
    CONSULTAR_USUARIO: 'CONSULTAR_USUARIO',
    CONSULTAR_SPOT: 'CONSULTAR_SPOT',
    MODIFICAR_COTIZACION_COSTOS: 'MODIFICAR_COTIZACION_COSTOS',
    MODIFICAR_COTIZACION_VENTAS: 'MODIFICAR_COTIZACION_VENTAS',
    MODIFICAR_ORGANIZACION: 'MODIFICAR_ORGANIZACION',
    MODIFICAR_SPOT: 'MODIFICAR_SPOT',
    MODIFICAR_TARIFA_EXPRESS: 'MODIFICAR_TARIFA_EXPRESS',
    MODIFICAR_USUARIO: 'MODIFICAR_USUARIO',
    SOLICITAR_COTIZACION: 'SOLICITAR_COTIZACION',
    PRICING: 'PRICING',
    COMERCIAL: 'COMERCIAL',
    GERENTE_COMERCIAL: 'GERENTE_COMERCIAL',
    ALTA_CHARGE_CODE: 'ALTA_CHARGE_CODE',
    CONSULTAR_CHARGE_CODE: 'CONSULTAR_CHARGE_CODE',
    BAJA_CHARGE_CODE: 'BAJA_CHARGE_CODE',
    BAJA_COTIZACION: 'BAJA_COTIZACION',
    CONSULTAR_SHIPMENT: 'CONSULTAR_SHIPMENT',
    MODIFICAR_SHIPMENT: 'MODIFICAR_SHIPMENT',
    SOLICITAR_SHIPMENT: 'SOLICITAR_SHIPMENT',
    EXPRESS_OP: 'EXPRESS_OP',
    CONSULTAR_STATS: 'CONSULTAR_STATS',
    PRICING_RULES: 'PRICING_RULES',
    ANALYTICS_INVOICED: 'ANALYTICS_INVOICED',
    ANALYTICS_QUOTATIONS: 'ANALYTICS_QUOTATIONS',
    ANALYTICS_REPORTS: 'ANALYTICS_REPORTS',
    OPERATIVE: 'OPERATIVE',
    OPERATIVE_CONTROLLER: 'OPERATIVE_CONTROLLER'
};

export const ROLES = {
    COMMERCIAL: 'COMMERCIAL',
    PRICING_AIR: 'PRICING_AIR',
    PRICING_SEA: 'PRICING_SEA',
    PRICING_ROAD: 'PRICING_ROAD',
    OPERATIONS_AIR: 'OPERATIONS_AIR',
    OPERATIONS_SEA: 'OPERATIONS_SEA',
    OPERATIONS_ROAD: 'OPERATIONS_ROAD',
    ADMINISTRATIVE: 'ADMINISTRATIVE',
    ADMIN: 'ADMIN',
};

export const COLORS = {
    PRIMARY: '#f68d2e',
    SECONDARY: '#212529',
    TERTIARY: '#babec2',
    GRAY_100: '#565656',
};

export const TRUE_FALSE_OPTIONS = [
    {value: 'true', label: 'SI'},
    {value: 'false', label: 'NO'},
];

export const CURRENCIES_OPTIONS = [
    {value: 'USD', label: 'USD'},
    {value: 'CLP', label: 'CLP'},
    {value: 'EUR', label: 'EUR'},
];

export const IVA = {
    percentage: 19,
    multiplier: 0.19,
}

export const QUOTATION_DOCUMENT_TYPES = [
    {value: 'PKL', label: 'PKL'},
    {value: 'CIV', label: 'CIV'},
    {value: 'MSDS', label: 'MSDS'},
    {value: 'MSC', label: 'OTROS'},
];

export const SHIPMENT_DOCUMENT_TYPES = [
    ...QUOTATION_DOCUMENT_TYPES,
    {value: 'FCO', label: 'FCO'},
    {value: 'AWB', label: 'AWB'},
];

export const INCOTERM_OPTIONS = [
    {value: 'EXW', label: 'EXW'},
    {value: 'FCA', label: 'FCA'},
    {value: 'FAS', label: 'FAS'},
    {value: 'FOB', label: 'FOB'},
    {value: 'CFR', label: 'CFR'},
    {value: 'CIF', label: 'CIF'},
    {value: 'CIP', label: 'CIP'},
    {value: 'CPT', label: 'CPT'},
    {value: 'DAP', label: 'DAP'},
    {value: 'DPU', label: 'DPU'},
    {value: 'DDP', label: 'DDP'},
];

export const TRANSPORT_OPTIONS = {
    SEA: [
        {value: 'FCL', label: 'Maritimo - FCL'},
        {value: 'LCL', label: 'Maritimo - LCL'},
        {value: 'RORO', label: 'Maritimo - RORO'},
    ],
    AIR: [
        {value: 'ULD', label: 'Aéreo - ULD'},
        {value: 'LSE', label: 'Aéreo - LSE'},
    ],
    ROAD: [
        {value: 'FTL', label: 'Terrestre - FTL'},
        {value: 'LTL', label: 'Terrestre - LTL'},
    ],
};

export const QUOTE_STATUS = {
    REQUESTED: 'REQUESTED',
    PENDING: 'PENDING',
    QUOTED: 'QUOTED',
    UPDATED: 'UPDATED',
    SENT: 'SENT',
    APPROVED: 'APPROVED',
    NOT_APPROVED: 'NOT_APPROVED',
    FUTURE: 'FUTURE',
};

export const EXPRESS_QUOTE_STATUS = {
    NOT_REQUESTED: 'NOT_REQUESTED',
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
};

export const INFO = ['description', 'validez', 'ruta', 'ttime', 'carrier', 'freeDays'];

export const SUPPORTED_LANGUAGES = {
    SPANISH: 'es',
    ENGLISH: 'en',
    PORTUGUESE: 'pt',
};

export const SUPPORTED_LANGUAGES_OPTIONS = [
    {value: SUPPORTED_LANGUAGES.SPANISH, label: 'Español'},
    {value: SUPPORTED_LANGUAGES.ENGLISH, label: 'English'},
    {value: SUPPORTED_LANGUAGES.PORTUGUESE, label: 'Português'},
];

export const SUPPORTED_CURRENCIES_FORMATTERS = {
    USA: 'usa',
    LOCALE: 'locale',
};

export const DATE_FORMATTERS = {
    MMYY: 'MM-YY',
    MMYYYY: 'MM-YYYY',
    DDMMMYY: 'DD-MMM-YY',
    DDMMYY: 'DD-MM-YY',
    DDMMYYYY: 'DD-MM-YYYY'
};

export const SUPPORTED_CURRENCIES_FORMATTERS_OPTIONS = [
    {value: SUPPORTED_CURRENCIES_FORMATTERS.USA, label: 'Formato USA'},
    {value: SUPPORTED_CURRENCIES_FORMATTERS.LOCALE, label: 'Formato Local'},
];

export const CONDITIONS = {
    EQUALS: 'EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    CONTAINS: 'CONTAINS',
    HAS: 'HAS',
    NOT_HAS: 'NOT_HAS',
};

export const CONDITIONS_OPTIONS = [
    {value: CONDITIONS.EQUALS, label: 'Igual a'},
    {value: CONDITIONS.NOT_EQUALS, label: 'Distinto de'},
    {value: CONDITIONS.CONTAINS, label: 'Contiene'},
    {value: CONDITIONS.HAS, label: 'Tiene'},
    {value: CONDITIONS.NOT_HAS, label: 'No tiene'},
];

export const FILTER_TYPES = {
    INCOTERM: 'INCOTERM',
    ORIGIN: 'ORIGIN',
    DESTINATION: 'DESTINATION',
    AUTORATE: 'AUTORATE',
    TRANSPORT_MODE: 'TRANSPORT_MODE',
    MODE: 'MODE',
};

export const FILTER_TYPES_OPTIONS = [
    {value: FILTER_TYPES.ORIGIN, label: 'Origen'},
    {value: FILTER_TYPES.DESTINATION, label: 'Destino'},
    {value: FILTER_TYPES.INCOTERM, label: 'Incoterm'},
    {value: FILTER_TYPES.AUTORATE, label: 'Auto Rate'},
    {value: FILTER_TYPES.TRANSPORT_MODE, label: 'Transporte'},
    {value: FILTER_TYPES.MODE, label: 'Modo'},
];

export const DRAFT_TYPES = {
    NEW_QUOTATION: 'NEW_QUOTATION',
    NEW_EXPRESS: 'NEW_EXPRESS',
    NEW_PRICING: 'NEW_PRICING',
};

export const ROUTES = {
    HOME: {path: '/home', permission: '', label: 'Home'},
    LOGIN: {path: '/login', permission: '', label: 'Login'},
    GENERAL_DASHBOARD: {
        path: '/general-dashboards',
        permission: PERMISSION.ANALYTICS_QUOTATIONS,
        label: 'Dashboard General'
    },
    // COMMERCIAL_DASHBOARD: {path: "/commercial-dashboards", permission: PERMISSION.ANALYTICS_QUOTATIONS, label: "Dashboard Comercial"},
    // ADMINISTRATIVE_DASHBOARD: {path: "/administrative-dashboards", permission: PERMISSION.ANALYTICS_QUOTATIONS, label: "Dashboard Administrativo"},
    REPORTS: {path: '/reports', permission: PERMISSION.ANALYTICS_REPORTS, label: 'Reportes'},
    MY_QUOTATIONS: {path: '/mis-cotizaciones', permission: PERMISSION.COMERCIAL, label: 'Mis Cotizaciones'},
    MY_QUOTATIONS_HISTORY: {
        path: '/mis-cotizaciones/historial',
        permission: PERMISSION.COMERCIAL,
        label: 'Mis Cotizaciones Historial'
    },
    FINALIZE_QUOTE: {path: '/finalizar', permission: PERMISSION.COMERCIAL},
    ASSIGNED: {path: '/asignadas', permission: PERMISSION.COMERCIAL, label: 'Asignadas'},
    QUOTATIONS_MANAGER: {
        path: '/cotizaciones-gerente',
        permission: PERMISSION.GERENTE_COMERCIAL,
        label: 'Cotizaciones'
    },
    PRICING_NEW_REQUESTS: {
        path: '/pricing/nuevas-solicitudes',
        permission: PERMISSION.PRICING,
        label: 'Nuevas Solicitudes'
    },
    PRICING_IN_PROGRESS: {path: '/pricing/en-proceso', permission: PERMISSION.PRICING, label: 'En Proceso'},
    PRICING_QUOTATIONS: {path: '/pricing/cotizaciones', permission: PERMISSION.PRICING, label: 'Cotizaciones'},
    PRICING_QUOTE: {path: '/pricing/cotizador', permission: PERMISSION.PRICING, label: 'Nueva Cotización'},
    ORGANIZATIONS: {path: '/organizaciones', permission: PERMISSION.CONSULTAR_ORGANIZACION, label: 'Organizaciones'},
    PROVIDERS: {path: '/proveedores', permission: PERMISSION.PRICING, label: 'Proveedores'},
    CHARGE_CODES: {path: '/charge-codes', permission: PERMISSION.ALTA_CHARGE_CODE, label: 'Charge Codes'},
    USERS: {path: '/usuarios', permission: PERMISSION.MODIFICAR_USUARIO, label: 'Usuarios'},
    OPERATIONS_GENERAL: {
        path: '/operativa/general',
        permission: PERMISSION.OPERATIVE,
        label: 'General',
    },
    OPERATIONS_EXPRESS: {path: '/operativa/express', permission: PERMISSION.CONSULTAR_SHIPMENT, label: 'Express'},
    OPERATIONS_EXPRESS_IN_PROGRESS: {path: '/operativa/express/en-proceso', permission: PERMISSION.CONSULTAR_SHIPMENT, label: 'En proceso'},
    OPERATIONS_EXPRESS_FINISHED: {path: '/operativa/express/finalizadas', permission: PERMISSION.CONSULTAR_SHIPMENT, label: 'Finalizadas'},
    PRICING_RATES: {path: '/pricing/tarifas', permission: PERMISSION.PRICING, label: 'Tarifas'},
    PRICING_QUOTATION_RULES: {path: '/pricing/reglas-cotizacion', permission: PERMISSION.PRICING_RULES, label: 'Reglas de Cotización'},
    DRAFTS: {path: '/borradores', permission: PERMISSION.CONSULTAR_COTIZACION, label: 'Borradores'},
    NOTES: {path: '/notas', permission: PERMISSION.PRICING, label: 'Notas'},
    THIRD_PARTY: {path: '/third-party', permission: '', label: 'Third Party'},
};

export const AUTO_INCREMENT_STEP = 1;

export const MAX_UPLOAD_SIZE = {label: '20MB', value: 20 * 1024 ** 2};

export const NO_RESULTS_FOUND_OPTION = {value: '', label: 'No se encontraron resultados', disabled: true};

export const NOTES_TYPES = {
    CLIENT: 'CLIENT',
    INTERNAL: 'INTERNAL',
    ON_REQUEST: 'ON_REQUEST'
};

export const STACKABLE_TYPES = {
    YES: 'YES',
    NO: 'NO',
    TOP_LOAD: 'TOP_LOAD',
};

export const STACKABLE_OPTIONS = [
    {value: STACKABLE_TYPES.YES, label: 'Si'},
    {value: STACKABLE_TYPES.NO, label: 'No'},
    {value: STACKABLE_TYPES.TOP_LOAD, label: 'Top Load'},
];

export const SERVICE_OPTIONS = [
    {value: 'FCL', label: 'Maritimo - FCL'},
    {value: 'LCL', label: 'Maritimo - LCL'},
    {value: 'RORO', label: 'Maritimo - RORO'},
    {value: 'ULD', label: 'Aéreo - ULD'},
    {value: 'LSE', label: 'Aéreo - LSE'},
    {value: 'FTL', label: 'Terrestre - FTL'},
    {value: 'LTL', label: 'Terrestre - LTL'},
];

export const DASHBOARD_INCOTERM_OPTIONS = [
    {value: 'EXW', label: 'EXW'},
    {value: 'FCA', label: 'FCA'},
    {value: 'CPT', label: 'CPT'},
    {value: 'CIP', label: 'CIP'},
    {value: 'DAP', label: 'DAP'},
    {value: 'DPU', label: 'DPU'},
    {value: 'DDP', label: 'DDP'},
    {value: 'CFR', label: 'CFR'},
    {value: 'FOB', label: 'FOB'},
    {value: 'FAS', label: 'FAS'},
    {value: 'CIF', label: 'CIF'},
];

export const CONTAINER_OPTIONS = [
    {value: '20FR', label: '20FR'},
    {value: '20GP', label: '20GP'},
    {value: '20HC', label: '20HC'},
    {value: '20NOR', label: '20NOR'},
    {value: '20OT', label: '20OT'},
    {value: '20PL', label: '20PL'},
    {value: '20RE', label: '20RE'},
    {value: '40FR', label: '40FR'},
    {value: '40GP', label: '40GP'},
    {value: '40HC', label: '40HC'},
    {value: '40NOR', label: '40NOR'},
    {value: '40OT', label: '40OT'},
    {value: '40PL', label: '40PL'},
    {value: '40RE', label: '40RE'},
    {value: '40REHC', label: '40REHC'},
    {value: '45HC', label: '45HC'}
];

export const TRANSPORT_MODE_OPTIONS = [
    {value: 'AIR', label: 'Aéreo'},
    {value: 'SEA', label: 'Marítimo'},
    {value: 'ROAD', label: 'Terrestre'},
    {value: 'EXPRESS', label: 'Express'},
];

export const DASHBOARD_STATUS_OPTIONS = [
    {value: 'REQUESTED', label: 'Solicitado'},
    {value: 'PENDING', label: 'Pendiente'},
    {value: 'QUOTED', label: 'Cotizado'},
    {value: 'UPDATED', label: 'Actualizado'},
    {value: 'SENT', label: 'Enviado'},
    {value: 'APPROVED', label: 'Aprobado'},
    {value: 'NOT_APPROVED', label: 'No Aprobado'},
    {value: 'FUTURE', label: 'A futuro'},
];

export const DASHBOARD_CARRIER_OPTIONS = [
    {value: '0', label: 'carrier-0'},
    {value: '1', label: 'carrier-1'},
    {value: '2', label: 'carrier-2'},
    {value: '3', label: 'carrier-3'},
    {value: '4', label: 'carrier-4'},
];

export const COMPANY_OPTIONS = [
    {value: 'MVD', label: 'Austral Cargo'},
    {value: 'AUZ', label: 'Austral Zona'}
];

export const AIR_MODE_OPTIONS = [
    {value: 'LSE', label: 'LSE'},
    {value: 'ULD', label: 'ULD'},
];

export const CURRENT_DATE = new Date();
export const INITIAL_DATE = new Date(CURRENT_DATE.getFullYear(), CURRENT_DATE.getMonth(), 1);

export const DASHBOARD_GLOBAL_FILTERS_INITIAL_STATE = {
    month: {
        key: 'month',
        selectedValue: [INITIAL_DATE, CURRENT_DATE],
        useGlobal: true,
        values: []
    },
    commercial: {
        key: 'commercial',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    transportMode: {
        key: 'transportMode',
        selectedValue: [],
        useGlobal: true,
        values: TRANSPORT_MODE_OPTIONS
    },
    provider: {
        key: 'provider',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    incoterms: {
        key: 'incoterms',
        selectedValue: [],
        useGlobal: true,
        values: DASHBOARD_INCOTERM_OPTIONS
    },
    origin: {
        key: 'origin',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    origins: {
        key: 'origins',
        selectedValue: [],
        useGlobal: true,
        values: []
    },
    destination: {
        key: 'destination',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    destinations: {
        key: 'destinations',
        selectedValue: [],
        useGlobal: true,
        values: []
    },
    container: {
        key: 'container',
        selectedValue: null,
        useGlobal: true,
        values: CONTAINER_OPTIONS
    },
    company: {
        key: 'company',
        selectedValue: null,
        useGlobal: true,
        values: COMPANY_OPTIONS
    },
    carrier: {
        key: 'carrier',
        selectedValue: null,
        useGlobal: true,
        values: DASHBOARD_CARRIER_OPTIONS
    },
    transitTime: {
        key: 'transitTime',
        selectedValue: null,
        useGlobal: true,
        values: null,
    },
    weightRange: {
        key: 'weightRange',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    volumeRange: {
        key: 'volumeRange',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    commodities: {
        key: 'commodities',
        selectedValue: [],
        useGlobal: true,
        values: []
    },
    airMode: {
        key: 'airMode',
        selectedValue: AIR_MODE_OPTIONS[0].value,
        useGlobal: true,
        values: AIR_MODE_OPTIONS
    },
};

export const OPERATION_FILTERS_INITIAL_STATE = {
    operatives: {
        key: 'operatives',
        selectedValue: [],
        values: []
    },
    commercials: {
        key: 'commercials',
        selectedValue: [],
        values: []
    },
    clientName: {
        key: 'clientName',
        selectedValue: null,
    },
    transportModes: {
        key: 'transportModes',
        selectedValue: [],
        values: TRANSPORT_MODE_OPTIONS
    },
    containerModes: {
        key: 'containerModes',
        selectedValue: [],
        values: CONTAINER_OPTIONS
    },
    origins: {
        key: 'origins',
        selectedValue: [],
        values: []
    },
    destinations: {
        key: 'destinations',
        selectedValue: [],
        values: []
    },
    etd: {
        key: 'etd',
        selectedValue: {group: TRANSLATION_KEYS.SYSTEM_ALL_DATES, dates: [null, null]},
        values: []
    },
    eta: {
        key: 'eta',
        selectedValue: {group: TRANSLATION_KEYS.SYSTEM_ALL_DATES, dates: [null, null]},
        values: []
    },
    status: {
        key: 'status',
        selectedValue: [],
        values: []
    },
    houseNumber: {
        key: 'houseNumber',
        selectedValue: null,
    },
    masterNumber: {
        key: 'masterNumber',
        selectedValue: null
    },
    shipmentKey: {
        key: 'shipmentKey',
        selectedValue: null
    },
    containerNumber: {
        key: 'containerNumber',
        selectedValue: null
    },
    quoteNumber: {
        key: 'quoteNumber',
        selectedValue: null
    },
};

export const STORAGE_PREFIXES = {
    operation: 'OPERATION',
    operationFilters: 'OPERATION_FILTERS'
};

export const SORT_OPTIONS = [
    {value: 'ASC', label: 'Menor a mayor'},
    {value: 'DESC', label: 'Mayor a menor'},
    {value: 'NONE', label: 'Por defecto'}
];

export const DASHBOARD_TABS_OPTIONS = {
    GENERAL: 'GENERAL',
    LSE: 'LSE',
    ULD: 'ULD',
    LCL: 'LCL',
    FCL: 'FCL',
    CLIENTS_PORTFOLIO: 'CLIENT_PORTFOLIO',
    OPERATIVE: 'OPERATIVE',
    PENDING_SELLS: 'PENDING_SELLS',
    PENDING_COSTS: 'PENDING_COSTS',
};

export const ALTERNATIVE_CARD_FIXED_HEIGHT = 36;

export const POSTAL_AWARE_COUNTRIES = ['AU', 'AT', 'BE', 'BR', 'CA', 'CL', 'CN', 'CO', 'CZ', 'CZ', 'DK', 'EC', 'FI', 'FR', 'DE', 'GR', 'GR', 'HU', 'IN', 'ID', 'IT', 'JP', 'KR', 'LU', 'MY', 'MX', 'NL', 'NL', 'NO', 'PH', 'PL', 'PT', 'US', 'RO', 'RU', 'RU', 'SA', 'SG', 'SK', 'SK', 'ZA', 'ES', 'SE', 'CH', 'TH', 'TR', 'GB', 'GB', 'GB', 'GB', 'US'];

export const SHIPMENT_STATUS = {
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    CANCELED: 'CANCELED',
    ON_HOLD: 'ON_HOLD',
    IN_COORDINATION: 'IN_COORDINATION',
    IN_TRANSIT: 'IN_TRANSIT',
    AT_BORDER: 'AT_BORDER',
    ARRIVED: 'ARRIVED',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    CLOSURE_PENDING: 'CLOSURE_PENDING',
    CLOSED: 'CLOSED',
};